import {InstagramEmbed, TikTokEmbed, YouTubeEmbed} from "react-social-media-embed";
import RecipeLinkData = App.Data.Models.RecipeLinkData;

export default function VideoEmbed({recipeLink}: { recipeLink: RecipeLinkData }) {
  const recipeLinkUrl: string = recipeLink.url ?? '';
  const host = new URL(recipeLinkUrl).hostname;

  if (host.includes('youtube')) {
    return (
      <YouTubeEmbed
        url={recipeLinkUrl}
        width={'100%'}
        height={600}
        className='w-full h-full'
      />
    );
  }

  if (host.includes('tiktok')) {
    return (
      <TikTokEmbed
        url={recipeLinkUrl}
        width={'100%'}
        height={600}
        className='w-full h-full'
      />
    );
  }

  if (host.includes('insta')) {
    return (
      <InstagramEmbed
        url={recipeLinkUrl}
        width={'100%'}
        height={600}
        className='w-full h-full'
      />
    );
  }

  return <div>Something isn't right</div>
}
